<template>
  <div id="register">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ showPage.name }}</h2>
          <p></p>
        </div>
        <div class="main">
          <component
            ref="page"
            :is="showPage.component"
            :showPageNumber="showPageNumber"
            @addPage="addPage"
            @skipNextStep="skipNextStep"
          ></component>
          <div class="btn_box">
            <el-button v-if="showPageNumber > 1" class="white_button" @click="reducePage" data-testid="back">
              {{ this.$t('register.btn.back') }}
            </el-button>
            <LoadingButton
              :text="$t('register.btn.next')"
              :callback="submit"
              class="purple_button"
              data-testid="next"
            ></LoadingButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDetails from '@/components/register/PersonalDetails';
import MoreAboutYou from '@/components/register/MoreAboutYou';
import AccountConfiguration from '@/components/register/AccountConfiguration';
import ConfirmID from '@/components/register/ConfirmID';
import LoadingButton from '@/components/LoadingButton';

export default {
  data() {
    return {
      progressList: [
        {
          name: this.$t('register.tabs.personalDetails'),
          id: 1,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.personalDetails.page2.pageTitle'),
          id: 2,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.tabs.moreAboutYou'),
          id: 3,
          component: 'MoreAboutYou'
        },
        {
          name: this.$t('register.tabs.accountConfig'),
          id: 4,
          component: 'AccountConfiguration'
        },
        {
          name: this.$t('register.tabs.confirmYourId'),
          id: 5,
          component: 'ConfirmID'
        },
        {
          id: 6,
          name: this.$t('register.tabs.fundYourAcc')
        }
      ],
      userProgress: {},
      showPage: null,
      showPageNumber: null
    };
  },
  components: { PersonalDetails, MoreAboutYou, AccountConfiguration, ConfirmID, LoadingButton },
  watch: {
    registerStep(number) {
      this.userProgress = this.progressList.find(f => f.id == number);
    },
    showPageNumber(val) {
      this.$nextTick(() => {
        const elMain = document.getElementById('elMain');
        elMain.scrollTop = 0;
      });
      if (val == 3) {
        this.showPageNumber = 4;
        this.showPage = this.progressList.find(f => f.id == this.showPageNumber);
      }
    }
  },
  beforeMount() {
    this.showPage = this.userProgress = this.progressList.find(f => f.id == this.registerStep);
    this.showPageNumber = this.registerStep;
    if (!this.disableField && this.registerStep >= 5) this.$store.commit('register/setDisableField', true);
  },
  methods: {
    skipNextStep() {
      if (this.showPageNumber != 4) return;
      this.showPageNumber += 2;
      this.showPage = this.progressList.find(f => f.id == this.showPageNumber);
      if (this.registerStep < this.showPageNumber) {
        this.$store.commit('common/setRegisterStep', this.showPageNumber);
        if (this.showPageNumber >= 5) this.$store.commit('register/setDisableField', true);
      }
      this.$router.push('/registerFinish');
    },
    addPage() {
      this.showPageNumber++;
      if (this.showPageNumber == 3) {
        this.showPageNumber = 4;
      }
      this.showPage = this.progressList.find(f => f.id == this.showPageNumber);
      if (this.registerStep < this.showPageNumber) {
        this.$store.commit('common/setRegisterStep', this.showPageNumber);
        if (this.showPageNumber == 5) this.$store.commit('register/setDisableField', true);
      }
    },
    reducePage() {
      if (this.showPageNumber > 1) {
        this.showPageNumber--;
        if (this.showPageNumber == 3) {
          this.showPageNumber = 2;
        }

        this.showPage = this.progressList.find(f => f.id == this.showPageNumber);
      }
    },
    submit() {
      this.$refs.page.submit();
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    disableField() {
      return this.$store.state.register.disableField;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/register.scss';
.btn_box /deep/ .el-button {
  width: 150px;
}
</style>
