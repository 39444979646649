<template>
  <div class="form">
    <component :is="currentComponent" ref="child"></component>
  </div>
</template>

<script>
import { apiProcess } from '@/resource/register';

export default {
  computed: {
    currentComponent() {
      return () => this.$config.componentLoader(this.regulator, 'register/ConfirmID').component;
    }
  },
  methods: {
    submit() {
      const idPoaFormComponent = this.$refs.child.$refs.regulator.$refs.idPoaForm;

      if (idPoaFormComponent) {
        idPoaFormComponent.$refs['ruleForm'].validate(valid => {
          if (!valid) return;
          else this.postApiProcess(idPoaFormComponent.form);
        });
      } else this.postApiProcess();
    },
    getApiData(idPoaForm = null, confirmIDForm) {
      if (idPoaForm) {
        return {
          ...idPoaForm,
          ...confirmIDForm
        };
      } else {
        return {
          ...confirmIDForm
        };
      }
    },
    postApiProcess(idPoaForm = null) {
      const confirmIDComponent = this.$refs.child.$refs.regulator;

      apiProcess(this.getApiData(idPoaForm, confirmIDComponent.form)).then(resp => {
        if (resp.data.code == 0) {
          if (confirmIDComponent.checkIdDocFile) {
            this.$store.commit('common/setPendingIDProofApproval', true);
            this.$store.commit('common/setRequireIDProof', false);
          }
          if (confirmIDComponent.checkPocDocFile) {
            this.$store.commit('common/setPendingAddressProofApproval', true);
            this.$store.commit('common/setRequireAddressProof', false);
          }
          this.$emit('addPage');
          this.$router.push('/registerFinish');
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 550px) {
  .form {
    margin-top: 16px !important;
  }
}
</style>
