<template>
  <div class="form">
    <component :is="currentComponent" ref="child"></component>
  </div>
</template>
<script>
import { apiProcess } from '@/resource/register';

export default {
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    currentComponent() {
      return () => this.$config.componentLoader(this.regulator, 'register/AccountConfiguration').component;
    }
  },
  methods: {
    submit() {
      return this.$refs.child.$refs.regulator.$refs['ruleForm'].validate(valid => {
        console.log(this.$refs.child.$refs.regulator.checkStatus);
        if (valid && this.$refs.child.$refs.regulator.checkStatus) {
          this.callApi();
        } else {
          console.log('error submit!!');
          return Promise.resolve(false);
        }
      });
    },
    callApi() {
      if (this.registerStep < 5) {
        console.log(this.$refs.child.$refs.regulator.form);
        apiProcess(this.$refs.child.$refs.regulator.form).then(resp => {
          if (resp.data.code == 0) {
            if (resp.data.data.skipNextStep) {
              this.$emit('skipNextStep');
            } else {
              this.$emit('addPage');
            }
          }
        });
      } else {
        this.$emit('addPage');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 550px) {
  .form {
    margin-top: 34px !important;
  }
}
</style>
